<template>
  <div>
    <div class="title-path">
      <a href="/">Home</a>
      <small class="fas fa-angle-right mx-1"></small>
      <a href="/reports">Reports</a>
    </div>
    <div class="row issue-wrapper">
      <div class="col-12 pt-4 px-4 bg-white">
        <h6 class="pt-2 text-dark text-header">
          Ticket Reports
        </h6>
        <hr>
        <div class="v-horizontal-scroll">
          <div class="col-scroll p-2" v-if="formControl.tickets.ticketList">
            <div class="card bg-white bg-shadow p-3 text-left h-100 cursor-pointer" v-on:click="toggleReport('ticket-list')">
              <small class="text-left">
                Ticket List
              </small>
              <hr>
              <small class="text-left text-muted">
                Export list of tickets from a given date range
              </small>
            </div>
          </div>
          <div class="col-scroll p-2" v-if="formControl.tickets.totalsPerClient">
            <div class="card bg-white bg-shadow p-3 text-left h-100 cursor-pointer" v-on:click="toggleReport('ticket-totals-client')">
              <small class="text-left">
                {{ this.user.role == 'Customer' ? 'Ticket Totals' : 'Ticket Totals per Client' }}
              </small>
              <hr>
              <small class="text-left text-muted">
                Export ticket count per client on a given date range
              </small>
            </div>
          </div>
          <div class="col-scroll p-2" v-if="formControl.tickets.totalsPerSegment">
            <div class="card bg-white bg-shadow p-3 text-left h-100 cursor-pointer" v-on:click="toggleReport('ticket-totals-segment')">
              <small class="text-left">
                Ticket Totals per Segment
              </small>
              <hr>
              <small class="text-left text-muted">
                Export ticket count per segment on a given date range
              </small>
            </div>
          </div>
          <div class="col-scroll p-2" v-if="formControl.tickets.totalsPerAgent">
            <div class="card bg-white bg-shadow p-3 text-left h-100 cursor-pointer" v-on:click="toggleReport('ticket-totals-agent')">
              <small class="text-left">
                Ticket Totals per Agent
              </small>
              <hr>
              <small class="text-left text-muted">
                Export ticket count per agent on a given date range
              </small>
            </div>
          </div>
          <div class="col-scroll p-2" v-if="formControl.tickets.totalsPerBUnit">
            <div class="card bg-white bg-shadow p-3 text-left h-100 cursor-pointer" v-on:click="toggleReport('ticket-totals-bunit')">
              <small class="text-left">
                Ticket Totals per Business Unit
              </small>
              <hr>
              <small class="text-left text-muted">
                Export ticket count per business unit on a given date range
              </small>
            </div>
          </div>
          <div class="col-scroll p-2" v-if="formControl.tickets.agingTickets">
            <div class="card bg-white bg-shadow p-3 text-left h-100 cursor-pointer" v-on:click="toggleReport('aging-ticket-totals')">
              <small class="text-left">
                Aging Ticket Totals
              </small>
              <hr>
              <small class="text-left text-muted">
                Export aging ticket count per client on a given year
              </small>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 pt-4 px-4 bg-white">
        <h6 class="pt-2 text-dark text-header">
          User Reports
        </h6>
        <hr>
        <div class="v-horizontal-scroll">
          <div class="col-scroll p-2" v-if="formControl.users.userList">
            <div class="card bg-white bg-shadow p-3 text-left h-100 cursor-pointer" v-on:click="toggleReport('user-list')">
              <small class="text-left">
                Customer List
              </small>
              <hr>
              <small class="text-left text-muted">
                Export list of customers
              </small>
            </div>
          </div>
        </div>
      </div>
    </div>
    <modal name="user-list" class="pb-5" :transition="`fade`" :width="`400px`" :height="`auto`" :scrollable="true">
      <UserListReport @loader="loader($event)"/>
    </modal>
    <modal name="ticket-list" class="pb-5" :transition="`fade`" :width="isMobile ? `400px` : `500px`" :height="`auto`" :scrollable="true">
      <TicketListReport @loader="loader($event)"/>
    </modal>
    <modal name="ticket-totals-client" class="pb-5" :transition="`fade`" :width="`400px`" :height="`auto`" :scrollable="true">
      <TicketTotalsperClient @loader="loader($event)"/>
    </modal>
    <modal name="ticket-totals-agent" class="pb-5" :transition="`fade`" :width="`400px`" :height="`auto`" :scrollable="true">
      <TicketTotalsperAgent @loader="loader($event)"/>
    </modal>
    <modal name="ticket-totals-segment" class="pb-5" :transition="`fade`" :width="`400px`" :height="`auto`" :scrollable="true">
      <TicketTotalsperSegment @loader="loader($event)"/>
    </modal>
    <modal name="ticket-totals-bunit" class="pb-5" :transition="`fade`" :width="`400px`" :height="`auto`" :scrollable="true">
      <TicketTotalsperBUnit @loader="loader($event)"/>
    </modal>
    <modal name="aging-ticket-totals" class="pb-5" :transition="`fade`" :width="`400px`" :height="`auto`" :scrollable="true">
      <AgingTicketTotals @loader="loader($event)"/>
    </modal>
  </div>
</template>

<script>
  import UserListReport from './Forms/UserListReport.vue'
  import TicketListReport from './Forms/TicketListReport.vue'
  import TicketTotalsperClient from './Forms/TicketTotalsperClient.vue'
  import TicketTotalsperAgent from './Forms/TicketTotalsperAgent.vue'
  import TicketTotalsperSegment from './Forms/TicketTotalsperSegment.vue'
  import TicketTotalsperBUnit from './Forms/TicketTotalsperBUnit.vue'
  import AgingTicketTotals from './Forms/AgingTicketTotals.vue'

  export default {
    components: {
      UserListReport,
      TicketListReport,
      TicketTotalsperClient,
      TicketTotalsperAgent,
      TicketTotalsperSegment,
      TicketTotalsperBUnit,
      AgingTicketTotals
    },
    props: {
      isMobile: Boolean
    },
    data() {
      return {
        user: this.$userData ? this.$userData.user : {},
        formControl: {
          tickets: {
            ticketList: false,
            totalsPerAgent: false,
            totalsPerClient: false,
            totalsPerSegment: false,
            totalsPerBUnit: false,
            agingTickets: false
          },
          users: {
            userList: false
          }
        }
      }
    },
    methods: {
      loader: function(val){
        this.$emit('loader', val)
      },
      toggleReport: function(modal){
        this.$modal.toggle(modal);
      },
      initializeReports: function(){
        this.formControl.tickets.ticketList = this.user.role != null
        this.formControl.tickets.totalsPerAgent = this.user.role != 'Customer'
        this.formControl.tickets.totalsPerClient = this.user.role != null
        this.formControl.tickets.totalsPerSegment = this.user.role != 'Customer'
        this.formControl.tickets.totalsPerBUnit = this.user.role != 'Customer'
        this.formControl.tickets.agingTickets = this.user.role != null
        this.formControl.users.userList = this.user.role != null
      }
    },
    mounted: function() {
      if(this.user._id){
        this.initializeReports()
      }
    }
  }
</script>
