<template>
  <div>
    <div class="modal-header">
      Export Customer List
      <h5><i class="fas fa-close cursor-pointer mx-2 pt-1 float-right" v-on:click="closeModal"></i></h5>
    </div>
    <div class="modal-body">
      <h6 class="text-header text-muted">Clients</h6>
      <MultiSelect
        :options="options.clients"
        :selected-options="filters.client"
        placeholder="All clients"
        :isDisabled="this.user.role === 'Customer'"
        ref="multiselect-client"
        @select="(items, last) => onSelect(items, last, 'client')"
      >
      </MultiSelect>
      <br>
      <h6 class="text-dark text-header">File format</h6>
      <ModelSelect
        v-model="filters.file_format"
        placeholder="Select file format"
        :options="options.file_formats"
      >
      </ModelSelect>
      <br><br>
      <div class="btn btn-block btn-primary bg-primary-default w-100" v-on:click="setFilters">
        Generate report
      </div>
    </div>
  </div>
</template>

<script>
  import moment from 'moment'
  import { MultiSelect, ModelSelect } from 'vue-search-select'

  export default {
    components: {
      MultiSelect, ModelSelect
    },
    data() {
      return {
        user: this.$userData.user,
        clients: [],
        date: '',
        filters: {
          client: [],
          file_format: 'csv'
        },
        options: {
          clients: [],
          file_formats: [
            { key: 'csv', text: 'CSV', value: 'csv' },
            { key: 'pdf', text: 'PDF', value: 'pdf' },
            { key: 'xslx', text: 'Excel', value: 'xslx' }
          ]
        }
      }
    },
    methods: {
      loader: function(val){
          this.$emit('loader', val)
      },
      closeModal: function(){
        this.$modal.toggle('user-list');
      },
      formatDate: function (date){
        return moment(date, 'YYYY/MM/DD HH:mm:ss').format("MMM DD, YYYY")
      },
      onSelect (items, last, filter_key, skip_focus = false) {
        this.filters[filter_key] = items
        if(!skip_focus)
          this.$refs[`multiselect-${filter_key}`].$el.querySelectorAll('input')[0].click()
      },
      async getClients(){
        try {
          const response = await this.$http.get(
            `${this.$apiEndpoint}/v1/clients`,
            {
              headers: {
                'Authorization': this.$userData.authToken,
                'Access-Control-Allow-Origin' : '*',
              }
            }
          );
          this.options.clients = response.data.clients.map((r) => {
            return { key: r._id, text: r.name, value: r._id }
          })
          if (this.user.role === 'Customer')
            this.onSelect ([{ key: this.user.client._id, text: this.user.client.name, value: this.user.client._id }], this.user.client._id, 'client', true)
        } catch (error) {
          console.log(error)
        }
      },
      setFilters(){
        let filters = this.filters
        let params = {
          client: filters.client.map(r => r.value),
          file_format: filters.file_format
        }
        this.generateReport(params)
      },
      async generateReport(params){
        if(!params || Object.keys(params).length === 0)
          return 0
        this.loader(true);
        try {
          const response = await this.$http.post(
            `${this.$apiEndpoint}/v1/reports/userList`,
            params,
            {
              headers: {
                'Authorization': this.$userData.authToken,
                'Access-Control-Allow-Origin' : '*',
              }
            }
          )
          if(response.data)
            this.$downloadFile(params.file_format, response.data, 'ITS_users_' + new Date().valueOf())
          this.loader(false)
        } catch (error) {
          console.log(error)
          this.loader(false)
        }
      }
    },
    mounted: function() {
      this.getClients();
    }
  }
</script>
