<template>
  <div>
    <div class="modal-header">
      Export Ticket Totals per Business Unit
      <h5><i class="fas fa-close cursor-pointer mx-2 pt-1 float-right" v-on:click="closeModal"></i></h5>
    </div>
    <div class="modal-body">
      <h6 class="pt-2 text-dark text-header">
        Ticket date range &nbsp;
      </h6>
      <div class="option-list w-100">
        <v-date-picker
          v-model="filters.dateRange"
          :placeholder="`${formatDate(filters.dateRange[0])} - ${formatDate(filters.dateRange[1])}`"
          :range="true"
          input-class="vue-datepicker w-100 text-muted border-0 px-2 my-1"
          class="w-100 form-control p-1"
        >
        </v-date-picker>
      </div>
      <br>
      <h6 class="text-header text-muted">Business Units</h6>
      <MultiSelect
        :options="options.bunits"
        :selected-options="filters.bunits"
        placeholder="All business units"
        :isDisabled="this.user.role === 'Customer'"
        ref="multiselect-bunits"
        @select="(items, last) => onSelect(items, last, 'bunits')"
      >
      </MultiSelect>
      <br>
      <h6 class="text-header text-muted">Clients</h6>
      <MultiSelect
        :options="options.clients"
        :selected-options="filters.clients"
        placeholder="All clients"
        :isDisabled="this.user.role === 'Customer'"
        ref="multiselect-clients"
        @select="(items, last) => onSelect(items, last, 'clients')"
      >
      </MultiSelect>
      <br>
      <h6 class="text-dark text-header">File format</h6>
      <ModelSelect
        v-model="filters.file_format"
        placeholder="Select file format"
        :options="options.file_formats"
      >
      </ModelSelect>
      <br><br>
      <div class="btn btn-block btn-primary bg-primary-default w-100" v-on:click="setFilters">
        Generate report
      </div>
    </div>
  </div>
</template>

<script>
  import moment from 'moment'
  import { MultiSelect, ModelSelect } from 'vue-search-select'

  export default {
    components: {
      MultiSelect, ModelSelect
    },
    data() {
      return {
        user: this.$userData.user,
        date: '',
        filters: {
          dateRange: this.$defaultDates,
          bunits: [],
          clients: [],
          file_format: 'csv'
        },
        options: {
          bunits: [],
          clients: [],
          file_formats: [
            { key: 'csv', text: 'CSV', value: 'csv' },
            { key: 'pdf', text: 'PDF', value: 'pdf' },
            { key: 'xslx', text: 'Excel', value: 'xslx' }
          ]
        }
      }
    },
    methods: {
      loader: function(val){
          this.$emit('loader', val)
      },
      closeModal: function(){
        this.$modal.toggle('ticket-totals-bunit');
      },
      formatDate: function (date){
        return moment(date, 'YYYY/MM/DD hh:mm:ss').format("MMM DD, YYYY")
      },
      onSelect (items, last, filter_key, skip_focus = false) {
        this.filters[filter_key] = items
        if(!skip_focus)
          this.$refs[`multiselect-${filter_key}`].$el.querySelectorAll('input')[0].click()
      },
      async getClients(){
        try {
          const response = await this.$http.get(
            `${this.$apiEndpoint}/v1/clients`,
            {
              headers: {
                'Authorization': this.$userData.authToken,
                'Access-Control-Allow-Origin' : '*',
              }
            }
          );
          this.options.clients = response.data.clients.map((r) => {
            return { key: r._id, text: r.name, value: r._id, code: r.code }
          })
          if (this.user.role === 'Customer')
            this.onSelect ([{ key: this.user.client._id, text: this.user.client.name, value: this.user.client._id }], this.user.client._id, 'clients', true)
        } catch (error) {
          console.log(error)
        }
      },
      async getBUnits() {
        try {
          const response = await this.$http.get(
            `${this.$apiEndpoint}/v1/bunits`,
            {
              headers: {
                'Authorization': this.$userData.authToken,
                'Access-Control-Allow-Origin' : '*',
              }
            }
          )

          this.options.bunits = response.data.bunits.filter(r => r.isActive).map(unit => {
            return { key: unit._id, text: unit.name, value: unit._id, code: unit.code }
          })

          if (this.user.role === 'Customer') {
            this.onSelect(this.user.bunit, this.user.bunit[this.user.bunit.length - 1], 'bunits', true)
          }

        } catch (error) {
          console.log(error)
        }
      },
      setFilters(){
        let filters = this.filters
        let params = {
          startsAt: moment(filters.dateRange[0]).startOf('day'),
          endsAt: moment(filters.dateRange[1]).endOf('day'),
          bunits: filters.bunits.map(bunit => bunit.value),
          client: filters.clients.map(r => r.value),
          file_format: filters.file_format
        }
        this.generateReport(params)
      },
      async generateReport(params){
        if(!params || Object.keys(params).length === 0)
          return 0
        this.loader(true);
        try {
          const response = await this.$http.post(
            `${this.$apiEndpoint}/v1/reports/ticketBUnitTotals`,
            params,
            {
              headers: {
                'Authorization': this.$userData.authToken,
                'Access-Control-Allow-Origin' : '*',
              }
            }
          )

          if(response.data)
            this.$downloadFile(params.file_format, response.data, 'ITS_tickets_business_unit_totals_' + new Date().valueOf())
          this.loader(false)
        } catch (error) {
          console.log(error)
          this.loader(false)
        }
      }
    },
    mounted: function() {
      this.getClients();
      this.getBUnits()
    }
  }
</script>
